import React from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import AddressForm from '../user/AddressForm';
import { BsQuestionCircle } from "react-icons/bs";
import { FaMinusSquare , FaPlusSquare  } from "react-icons/fa";
import { Button, Card, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';

import '../../views/main.css';

import { useUser } from '../../context/UserContext';
import { useAuth } from '../../context/AuthContext';
import { useData } from '../../context/DataContext';

export default function CreateBaselineResume() {
    const history = useNavigate();
    const {
        user, 
        setUser,
        handleResumeChange,
        sanitizeUserInput,
        handleExperienceChange,
        addExperienceEntry,
        removeExperienceEntry,
        generateUUID,
        includeAddress,
        setIncludeAddress,
        canCreateMore,
        setSuccessMessage,
        setError,
    } = useUser();
    const {
        setLoading
    } = useAuth();
    const {
        saveBaselineResume,
        userToken
    } = useData();
    const userExperience = "Detail your relevant job history (Required):";
    const userEducation = "List your education and certifications (Required):";
    const userSkills = "Specify your relevant skills (Required):";
    const userAdditional = "Include any additional relevant information (Optional):";
    const token = userToken;
    const addNewResume = (newResume) => {
        setUser((prevState) => ({
            ...prevState,
            resume: [...prevState.resume, newResume],
        }));
    };

    const handleSubmit = async () => {
        if (!canCreateMore("baseResume")){
            history('/OverLimit');
            setError("You have reached the maximum number of base resumes for your subscription.");
            return;
        }
        const timeStamp = new Date().toISOString();
        const { experience, education, skills } = user.resume[0];
    
        if (!education.trim() || !skills.trim()) {
            setError('Experience, Education, and Skills are required fields.');
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            const rateLimitResponse = await axios.get(
                'https://us-central1-resumancy.cloudfunctions.net/limitedFunction-limitedFunction',
                { headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }}
            );
    
            if (rateLimitResponse.status === 429) {
                setError("Too many requests. Please wait a moment before trying again.");
                setLoading(false);
                return;
            }

            const response = await axios.post(
                'https://us-central1-resumancy.cloudfunctions.net/generate_base_resume-generate_base_resume',
                { user },
                { headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }}
            );
    
            const { summary, experienceSection, skillsSection, educationSection } = response.data;
    
            let parsedExperience = [];
            try {
                parsedExperience = JSON.parse(experienceSection.data);
            } catch (parseError) {
                console.error('Error parsing experience section:', parseError);
            }
    
            const updatedUser = {
                ...user,
                address: {
                    ...user.address,
                    useAddress: includeAddress,
                },
                resume: [
                    {
                        ...user.resume[0],
                        id: generateUUID(),
                        summary: summary.data,
                        experience: parsedExperience,
                        skills: skillsSection.data,
                        education: educationSection.data,
                        usage: {
                            ...user.resume[0].usage,
                            timeStamp,
                            baseResume: true,
                            useAddress: includeAddress,
                        },
                    },
                ],
                subscription: {
                    ...user.subscription,
                    usage: {
                        ...user.subscription.usage,
                        baseResume: 1,
                    },
                },
            };

            await saveBaselineResume(updatedUser.id, {
                resume: updatedUser.resume,
                subscription: updatedUser.subscription,
            });
            addNewResume(updatedUser);
    
            history('/Review');
        } catch (error) {
            console.error('Error generating resume:', error);
            setError('Failed to generate resume. Please try again.');
        } finally {
            setLoading(false);
            setSuccessMessage('Base Resume Successfully Created')
        }
    };
    
    return (
        <>
            <div className='pageWrapper'>
                <div className='cardInnerDiv' id='scratchCardInnerDiv'>
                <Card>
                    <Card.Body>
                        <div className='ScratchInnerDiv'>
                            <h3>Create Base Resume</h3>
                            <p  className="required-note">Fields marked with <span className="required-indicator">*</span> are required.</p>
                            <Form>
                                <div>
                                </div>
                                <Form.Group>
                                    <Form.Label>Want to include your address?</Form.Label>
                                    <br/>
                                <Form.Check
                                        inline
                                        label="Yes"
                                        type={'checkbox'}
                                        onChange={() => setIncludeAddress(true)}
                                        checked={includeAddress}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        type={'checkbox'}
                                        onChange={() => setIncludeAddress(false)}
                                        checked={!includeAddress}
                                    />
                                    {includeAddress && (
                                        <div>
                                            <sub>
                                                You have the option to include partial address information if you prefer. 
                                                <br/>
                                                Feel free to fill out only the fields you wish to provide.
                                            </sub>
                                            <AddressForm/>
                                        </div>
                                    )}
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                        Experience<span className="required-indicator">*</span> {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userExperience}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        {/* <sub>({user.resume[0].experience.length} / 8000)</sub> */}
                                        {/* this will need modified in order to cap each sections */}
                                    </Form.Label>
                                    <div className='createResumePlusAndMinus'>
                                        <FaPlusSquare onClick={addExperienceEntry}  size={25} className='creationButton' id='CreateResumePlusButton'/>
                                    </div>
                                    {(user.resume[0].experience || []).map((exp, index) => (
                                        <div key={index} className="experience-entry" style={{border: '1px solid lightblue', padding: '5px', margin: '5px', borderRadius: '.5REM'}}>
                                            <Form.Control 
                                                type='text'
                                                placeholder='Job Title'
                                                value={exp.expJobTitle}
                                                onChange={(e) => handleExperienceChange(index, 'expJobTitle', sanitizeUserInput(e.target.value))}
                                                className="mb-2"
                                            />
                                            <Form.Group className="d-flex gap-3 align-items-center">
                                            <Form.Control 
                                                type='text'
                                                placeholder='Company Name'
                                                value={exp.expCompanyName}
                                                onChange={(e) => handleExperienceChange(index, 'expCompanyName', sanitizeUserInput(e.target.value))}
                                                className="mb-2"
                                            />
                                            <Form.Control 
                                                type='text'
                                                placeholder='Employment Duration (e.g., Jan 2020 - Dec 2022)'
                                                value={exp.expTime}
                                                onChange={(e) => handleExperienceChange(index, 'expTime', sanitizeUserInput(e.target.value))}
                                                className="mb-2"
                                                />
                                            </Form.Group>
                                            <textarea
                                                rows='5'
                                                placeholder='Description'
                                                value={exp.expDesc}
                                                onChange={(e) => handleExperienceChange(index, 'expDesc', sanitizeUserInput(e.target.value))}
                                                className="mb-3 form-control"
                                            />
                                            <div className='createResumePlusAndMinus'>
                                                <FaMinusSquare onClick={() => removeExperienceEntry(index)} size={25} className='creationButton' id='CreateResumeMinusButton'/>
                                            </div>
                                        </div>
                                    ))}
                                    <Form.Text className='text-muted'>
                                        Work Experience, this should include: Dates of Employment, Employer, Job Title, and a brief description of your role.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                            Education<span className="required-indicator">*</span> {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userEducation}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({user.resume[0].education.length} / 500)</sub>
                                    </Form.Label>
                                    <textarea
                                    rows='5'
                                    value={user.resume[0].education}
                                    onChange={(e) => handleResumeChange('education', sanitizeUserInput(e.target.value))}
                                    maxLength='500'
                                    className="mobile-textarea form-control"
                                    />
                                    <Form.Text className='text-muted'>
                                        Education and Certifications
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                            Skills<span className="required-indicator">*</span> {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userSkills}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({user.resume[0].skills.length} / 1000)</sub>
                                    </Form.Label>
                                    <textarea
                                    rows='5'
                                    value={user.resume[0].skills}
                                    onChange={(e) => handleResumeChange('skills', sanitizeUserInput(e.target.value))}
                                    maxLength='1000'
                                    className="mobile-textarea form-control"
                                    />
                                    <Form.Text className='text-muted'>
                                        Skills relevant to Employment
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                        Anything Additional {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userAdditional}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({user.resume[0].additional.length} / 1000)</sub>
                                    </Form.Label>
                                    <textarea
                                    rows='5'
                                    value={user.resume[0].additional}
                                    onChange={(e) => handleResumeChange('additional', sanitizeUserInput(e.target.value))}
                                    maxLength='1000'
                                    className="mobile-textarea form-control"
                                    />
                                    <Form.Text className='text-muted'>
                                        Any additional information you think may be beneficial
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>
                <Button 
                    aria-label='submit button'
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                </div>
                <br/>
            </div>
        </>
    )
}